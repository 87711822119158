import {Component} from 'react'
import PropTypes from 'prop-types'
import {fromJS, List} from 'immutable'

import FieldSet from './FieldSet'
import * as Labels from './Labels'
import formFieldFactory from '../formFieldFactory'
import {onBlur, onFocus} from '../../../../dev_only/forms_playground/lib/tools'
import FieldErrors from './FieldErrors'
import PasswordFlyout from '../../PasswordFlyout'
import criteria from '../../../password_criteria.json'
import showInputIcon from '../../../../shared_assets/v2/unmask-icon.svg'
import hideInputIcon from '../../../../shared_assets/v2/mask-icon.svg'

import './password.scss'

export class Password extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showFlyout: false,
      showPassword: false,
      showConfirmation: false,
      criteria: fromJS(criteria)
    }
    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onChange = this.onChange.bind(this)
    this.toggleMask = this.toggleMask.bind(this)
    this.togglePasswordMask = this.togglePasswordMask.bind(this)
    this.toggleConfirmationMask = this.toggleConfirmationMask.bind(this)
  }

  value() {
    return fromJS({
      password: this.refs.password.value,
      'password-confirmation': this.refs.password_confirmation.value
    })
  }

  fieldProps() {
    return {
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus
    }
  }

  onChange(event) {
    this.isPasswordField(event) && this.validatePassword(event)

    if (this.props.onChange)
      this.props.onChange()
  }

  validatePassword(event) {
    const password = event.target.value

    this.setState(prevState => ({
      ...prevState,
      criteria: this.state.criteria.map(
        criterion => criterion.set('isValid', criterion.get('regex').test(password))
      )
    }))

    if (this.state.criteria.every(criterion => criterion.get('regex').test(password)))
      this.setState({showFlyout: false})
    else
      this.setState({showFlyout: true})
  }

  onBlur(event) {
    this.isPasswordField(event) && this.setState({showFlyout: false})
    onBlur(event)
  }

  isPasswordField(event) { return event.target.name === 'password' }

  onFocus(event) {
    this.isPasswordField(event) && this.setState({showFlyout: true})
    onFocus(event)
  }

  toggleMask(field) {
    if (field === 'password') this.setState({showPassword: !this.state.showPassword})
    else this.setState({showConfirmation: !this.state.showConfirmation})
  }

  togglePasswordMask() {
    this.toggleMask('password')
  }

  toggleConfirmationMask() {
    this.toggleMask('confirmation')
  }

  render() {
    const {data, errors} = this.props
    const legend = data.get('legend') ? data.get('legend') : 'Password'

    return (
      <div className='password-v2'>
        <FieldSet className='controls-group'>
          <legend>{legend}</legend>
          <Labels.Label className='password-label' errors={errors.getIn([0, 'password'], List())}>
            <Labels.Accessible>{legend}</Labels.Accessible>
            <input
              {...this.fieldProps()}
              name='password'
              ref='password'
              type={this.state.showPassword ? 'text' : 'password'}
            />
            <button className='mask-icon-and-text' onClick={this.togglePasswordMask} type='button'>
              <img alt='' className='mask-icon' src={this.state.showPassword ? hideInputIcon : showInputIcon} />
              {this.state.showPassword ? 'Hide' : 'Show'}
            </button>
            <FieldErrors errors={errors.getIn([0, 'password'])} />
          </Labels.Label>
          {this.state.showFlyout && <PasswordFlyout header='Password must contain at least:' criteria={this.state.criteria.toJS()} />}
        </FieldSet>
        <FieldSet className='controls-group'>
          <legend>Confirm Password:</legend>
          <Labels.Label className='password-label' errors={errors.getIn([0, 'password-confirmation'], List())}>
            <Labels.Accessible>Confirm Password:</Labels.Accessible>
            <input
              {...this.fieldProps()}
              name='password-confirmation'
              ref='password_confirmation'
              type={this.state.showConfirmation ? 'text' : 'password'}
            />
            <button className='mask-icon-and-text' onClick={this.toggleConfirmationMask} type='button'>
              <img alt='' className='mask-icon' src={this.state.showConfirmation ? hideInputIcon : showInputIcon} />
              {this.state.showConfirmation ? 'Hide' : 'Show'}
            </button>
            <FieldErrors errors={errors.getIn([0, 'password-confirmation'])} />
          </Labels.Label>
        </FieldSet>
      </div>
    )
  }
}

Password.propTypes = {
  data: PropTypes.instanceOf(Map),
  errors: PropTypes.array,
  onChange: PropTypes.func
}

export default formFieldFactory(<Password />)
