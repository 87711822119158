import PropTypes from 'prop-types'

import ButtonLoadingSpinner from './ButtonLoadingSpinner'
import StyledButton from './StyledButton'
import StyledButtonText from './StyledButtonText'

const Button = ({loading, ...otherProps}) => (
  <StyledButton
    {...otherProps}
    className='button'
    disabled={otherProps.disabled || loading}
    size='large'
    variant='contained'>
    <StyledButtonText loading={loading} variant='button'>
      {otherProps.children}
    </StyledButtonText>
    {
      loading &&
      <ButtonLoadingSpinner data-testid='button-loading-spinner' size={20} />
    }
  </StyledButton>
)

Button.defaultProps = {
  color: 'primary'
}

Button.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func
}

export default Button
