import csv from './csv.svg'
import doc from './doc.svg'
import image from './image.svg'
import mp3 from './mp3.svg'
import pdf from './pdf.svg'
import ppt from './ppt.svg'
import unknown from './unknown.svg'
import xls from './xls.svg'
import zip from './zip.svg'

export default {
  csv,
  doc,
  image,
  mp3,
  pdf,
  ppt,
  unknown,
  xls,
  zip
}
