import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {fileExtension} from '../lib/tools'

import fileUploadIcons from '../../../../shared_assets/v2/file_upload_types'

import './fileIcon.scss'

const ICON_TYPE_BY_EXTENSION = Map({
  csv: 'csv',
  doc: 'doc',
  docx: 'doc',
  gif: 'image',
  jpeg: 'image',
  jpg: 'image',
  mp3: 'mp3',
  pdf: 'pdf',
  png: 'image',
  ppt: 'ppt',
  pptx: 'ppt',
  svg: 'image',
  txt: 'doc',
  xls: 'xls',
  xlsx: 'xls',
  zip: 'zip'
})

const iconByFileName = fileName => fileUploadIcons[ICON_TYPE_BY_EXTENSION.get(fileExtension(fileName), 'unknown')]

const FileIcon = ({fileName}) => (
  <img
    alt={`File type icon for ${fileName}`}
    className='file-icon'
    src={iconByFileName(fileName)}
  />
)

FileIcon.propTypes = {
  fileName: PropTypes.string
}

export default FileIcon
